import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = ["index_table",
    "report_period", "sources", "countries", "partners", "sales_directors" ]

  connect() {
    if (this.hasIndex_tableTarget) {
      this.createIndexTable();
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let filters = []

    if (this.hasReport_periodTarget) {
      filters.push({
        field: "report_period",
        type: "=",
        value: this.report_periodTarget.value
      })
    }

    if (this.hasSourcesTarget) {
      filters.push({
        field: "sources_ids",
        type: "=",
        value: this.sourcesTarget.choices.getValue(true)
      })
    }

    if (this.hasCountriesTarget) {
      filters.push({
        field: "countries_ids",
        type: "=",
        value: this.countriesTarget.choices.getValue(true)
      })
    }

    if (this.hasPartnersTarget) {
      filters.push({
        field: "partners_ids",
        type: "=",
        value: this.partnersTarget.choices.getValue(true)
      })
    }

    if (this.hasSales_directorsTarget) {
      filters.push({
        field: "sales_directors_ids",
        type: "=",
        value: this.sales_directorsTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      this.index_tableTarget.tabulator.setFilter(filters);
    }
  }

  async createIndexTable() {
    const table_target = this.index_tableTarget;
    const url = table_target.getAttribute("data-table-load-url");
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");


    let table_props = {
      persistenceID: "marketing_report_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

}
