// import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = ["index_table", "search", "countries", "cities", "price_from", "price_to"]

  connect() {
    if (this.hasIndex_tableTarget) {
      this.createIndexTable();
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasCountriesTarget) {
      filters.push({
        field: "countries_ids",
        type: "=",
        value: this.countriesTarget.choices.getValue(true)
      })
    }

    if (this.hasCitiesTarget) {
      filters.push({
        field: "cities_ids",
        type: "=",
        value: this.citiesTarget.choices.getValue(true)
      })
    }

    if (this.hasPrice_fromTarget) {
      filters.push({
        field: "price_from",
        type: "=",
        value: this.price_fromTarget.value
      })
    }

    if (this.hasPrice_toTarget) {
      filters.push({
        field: "price_to",
        type: "=",
        value: this.price_toTarget.value
      })
    }

    if (filters.length) {
      this.index_tableTarget.tabulator.setFilter(filters);
    }
  }

  async createIndexTable() {
    const table_target = this.index_tableTarget;
    const url = table_target.getAttribute("data-table-load-url");
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");


    let table_props = {
      persistenceID: "foreign_suppliers_prices_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 200) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      // pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      ajaxProgressiveLoad: "scroll",
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

}
