import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "dashboard_table",
    "rooms_table",
    "statuses_table",
    'details_table',
    "report_period",
    "search",
    "tour_operators",
    "hotels",
    "statuses"]

  connect() {
    if (this.hasDashboard_tableTarget) {
      this.createDashboardTable();
    }
    if (this.hasRooms_tableTarget) {
      this.createRoomsTable();
    }
    if (this.hasStatuses_tableTarget) {
      this.createStatusesTable();
    }
    if (this.hasDetails_tableTarget) {
      this.createDetailsTable();
    }
  }

  filterTable() {
    this.setFilterToTable();
  }


  setFilterToTable() {
    let filters = []

    console.log( 'in set filters')
    if (this.hasReport_periodTarget) {
      filters.push({
        field: "report_period",
        type: "=",
        value: this.report_periodTarget.value
      })
    }

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasTour_operatorsTarget) {
      filters.push({
        field: "tour_operator_ids",
        type: "=",
        value: this.tour_operatorsTarget.choices.getValue(true)
      })
    }

    if (this.hasHotelsTarget) {
      filters.push({
        field: "hotels_ids",
        type: "=",
        value: this.hotelsTarget.choices.getValue(true)
      })
    }

    if (this.hasStatusesTarget) {
      filters.push({
        field: "statuses",
        type: "=",
        value: this.statusesTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      this.dashboard_tableTarget.tabulator.setFilter(filters);
      this.rooms_tableTarget.tabulator.setFilter(filters);
      this.statuses_tableTarget.tabulator.setFilter(filters);
      this.details_tableTarget.tabulator.setFilter(filters);
    }
  }


  async createDashboardTable() {
    const table_target = this.dashboard_tableTarget;
    const url = table_target.getAttribute("data-table-load-url");
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");


    let table_props = {
      persistenceID: "hotels_report_dashboard_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createRoomsTable() {
    const table_target = this.rooms_tableTarget;
    const url = table_target.getAttribute("data-table-load-url");
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "hotels_report_rooms_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  async createStatusesTable() {
    const table_target = this.statuses_tableTarget;
    const url = table_target.getAttribute("data-table-load-url");
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "hotels_report_statuses_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createDetailsTable() {
    const table_target = this.details_tableTarget;
    const url = table_target.getAttribute("data-table-load-url");
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "hotels_report_details_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> "
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }
}